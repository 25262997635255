import React from 'react';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import Carousel from 'react-bootstrap/Carousel';
import Card from 'react-bootstrap/Card';
import Image1 from '../images/vidushan.webp';
import Image2 from '../images/lithmi.webp';
import Image3 from '../images/chathura.webp';
import Image4 from '../images/hiruni.webp';
import Image5 from '../images/uchintha.webp';
import Image6 from '../images/ridmi.webp';
import Image7 from '../images/hirun.webp';
import 'bootstrap/dist/css/bootstrap.min.css';

function DarkVariantExample() {

  const handlePhoneClick = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEmailClick = (email) => {
    navigator.clipboard.writeText(email)
      .then(() => alert(`Email ${email} copied to clipboard`))
      .catch((error) => console.error('Error copying text: ', error));
    window.location.href = `mailto:${email}`;
  };

  const clickableStyle = { cursor: 'pointer' };

  const cardsData = [
    {
      image: Image1,
      name: "Pramuditha Vidushan",
      role: "Chief Coordinator",
      phone: "(+94) 70 111 6121",
      email: "vidushan@viyrl.com"
    },
    {
      image: Image2,
      name: "Lithmi Kihansa",
      role: "Chief Coordinator",
      phone: "(+94) 70 676 1613",
      email: "liteenilanjith@gmail.com"
    },
    {
      image: Image3,
      name: "Chathura Kumarasinghe",
      role: "Financial Coordinator",
      phone: "(+94) 77 629 0618",
      email: "kumarasinghac221@gmail.com"
    },
    {
      image: Image4,
      name: "Hiruni Withanagamage",
      role: "Financial Coordinator",
      phone: "(+94) 71 562 0806",
      email: "hirunihansika625@gmail.com"
    },
    {
      image: Image5,
      name: "Uchintha Bandara",
      role: "Partnership Coordinator",
      phone: "(+94) 71 803 3621",
      email: "bandarauchintha505@gmail.com"
    },
    {
      image: Image6,
      name: "Ridmi Silva",
      role: "Partnership Coordinator",
      phone: "(+94) 76 260 9770",
      email: "liyanagesilva2001@gmail.com"
    },
    {
      image: Image7,
      name: "Hirun Senarathna",
      role: "Marketing Coordinator",
      phone: "(+94) 70 442 2486",
      email: "hirunsenarathna@gmail.com"
    },
  ];

  return (
    <div className="team-cards-section" style={{ padding: '20px', color: '#fff' }}>
      <Carousel interval={null}>
        {cardsData.map((card, idx) => (
          <Carousel.Item key={idx}>
            <div className="card-wrapper">
              <Card className="custom-card" style={{ width: '25rem', color: '#fff', borderRadius: '25px', background: "rgba(255, 255, 255, 0.2)",
                  boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                  backdropFilter: "blur(1.6px)",
                  WebkitBackdropFilter: "blur(1.6px)",
                  overflow: "hidden" }}>
                <Card.Img variant="top" src={card.image} className="card-img-no-border circular-image" />
                <Card.Body>
                  <Card.Title><center>{card.name}</center></Card.Title><br></br>
                  <Card.Text>
                    <h5 style={{ color: '#F1B61E', textAlign: 'center' }}>{card.role}</h5><br></br>
                    <center>
                      <p onClick={() => handlePhoneClick(card.phone)} style={clickableStyle}><AiOutlinePhone style={{ marginRight: "10px" }} />{card.phone}</p>
                      <p onClick={() => handleEmailClick(card.email)} style={clickableStyle}><AiOutlineMail style={{ marginRight: "10px" }} />{card.email}</p>
                    </center>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div><br></br><br></br>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}

export default DarkVariantExample;
