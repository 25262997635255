import React from 'react';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";

function DarkVariantExample() {
    return (
        <div className="container">
            <style>
                {`
                .custom-card {
                    border-radius: 25px;
                    background: rgba(255, 255, 255, 0.2);
                    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                    backdrop-filter: blur(1.6px);
                    -webkit-backdrop-filter: blur(1.6px);
                    overflow: hidden;
                    transition: transform 0.3s ease;
                }

                .custom-card img {
                    border-radius: 50%;
                    width: 50%;
                    margin: auto;
                    margin-top: 20px;
                    transition: transform 0.3s ease;
                }

                .custom-card:hover {
                    transform: scale(1.05);
                }

                .custom-card:hover img {
                    transform: scale(1.2);
                }

                .custom-card:hover .card-price {
                    transform: translateY(10px);
                }

                .card-text {
                    transition: transform 0.3s ease;
                }
                `}
            </style>
            <div className="row justify-content-center">
                <div className="col-12 col-md-4 mb-4 c1 order-2 order-md-1">
                    <Card className="custom-card">
                        <Card.Img variant="top" src="3.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "orbitron" }}>1<sup>st</sup> Runner Up</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: '#F1B61E', fontSize: '3.0rem' }}>
                                LKR 75,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-12 col-md-4 mb-4 c1 order-1 order-md-2">
                    <Card className="custom-card">
                        <Card.Img variant="top" src="1.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "orbitron" }}>Winner</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: '#F1B61E', fontSize: '3.0rem' }}>
                                LKR 125,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-12 col-md-4 mb-4 c1 order-3 order-md-3">
                    <Card className="custom-card">
                        <Card.Img variant="top" src="2.webp" />
                        <Card.Body style={{ backgroundColor: 'transparent', height: '180px' }}>
                            <Card.Title style={{ textAlign: 'center', fontSize: '1.5rem', fontFamily: "orbitron" }}>2<sup>nd</sup> Runner Up</Card.Title>
                            <Card.Text className="mobile-font-size card-price" style={{ textAlign: 'center', color: '#F1B61E', fontSize: '3.0rem' }}>
                                LKR 50,000
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default DarkVariantExample;
